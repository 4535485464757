.content section .collapsibleSectionContainer {
  border-bottom: 1px solid var(--color-1--20);
}
.content section .collapsibleSectionContainer:last-of-type {
  border-bottom: none;
}

.content section .collapsibleSectionContainer .collapsibleSectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0.5rem 0;
}
.content section .collapsibleSectionContainer .collapsibleSectionHeader:hover {
  background: var(--color-1--20);
}
.content section .collapsibleSectionContainer .collapsibleSectionHeader:hover img {
  opacity: 1;
}
.content section .collapsibleSectionContainer .collapsibleSectionHeader .carretUp {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.content section .collapsibleSectionContainer .collapsibleSectionContent {
  padding: 0 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  display: block;
}
.content section .collapsibleSectionContainer p:last-of-type {
  margin-bottom: 1rem;
}

.content section .collapsibleSectionContainer.collapsed .collapsibleSectionContent {
  display: none;
}
.content section .collapsibleSectionContainer.collapsed .collapsibleSectionHeader .carretUp {
  transform: rotate(180deg);
  opacity: 0.5;
}
.content section .collapsibleSectionContainer.collapsed .collapsibleSectionHeader:hover .carretUp {
  opacity: 1;
}