:root {
  --color-1: #355070;
  --color-2: #6D597A;
  --color-3: #B56576;
  --color-4: #E56B6F;
  --color-5: #EAAC8B;
  --color-1--10: #ebeef1;
  --color-1--20: #d7d1da;
  --color-2--10: #f1eff2;
  --color-3--10: #f8f0f2;
  --color-4--10: #fdf1f1;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  padding: 0;
  margin: 0;
  font-weight: 400;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.75rem;
}

p {
  line-height: 1.5rem;
}

div {
  box-sizing: border-box;
}

strong {
  font-weight: 600;
}

a {
  font-weight: 600;
  color: var( --color-1 );
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  margin: 0;
  padding-left: 1.5rem;
}

ul li {
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 1100px) {
  .App .content section {
    grid-template-columns: 1fr;
  }
  .App .content section .left {
    text-align: center;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .App .content section.intro .left .social {
    justify-content: flex-start;
    margin: 0 auto;
  }
}

