.content {
  padding: 0 2rem;
  margin: 4rem auto;
  max-width: 73rem;
}

.content section {
  display: grid;
  grid-template-columns: 15em 1fr;
  grid-column-gap: 2em;
  padding-bottom: 2rem;
}

.content section .left {
  text-align: right;
}

.content section .right {
  padding: 1em;
  padding-left: calc( 2rem + 6px );
  border-radius: 0.5rem;
  position: relative;
}

.content section .right .rightBar {
  width: 6px;
  border-radius: 3px;
  height: calc( 100% - 2rem );
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.content section .right p:first-of-type {
  margin-top: 0;
}

.content section .right p:last-of-type {
  margin-bottom: 0;
}

.content section .right p a {
  margin-left: 0.25rem;
}

.content section.intro .left h1 {
  color: var(--color-1);
}

.content section.intro .left .leftBar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 1rem;
  margin: 1rem 0;
}
.content section.intro .left .leftBar div {
  height: 1rem;
}
.content section.intro .left .leftBar div.color1 {
  background: var(--color-1);
}
.content section.intro .left .leftBar div.color2 {
  background: var(--color-2);
}
.content section.intro .left .leftBar div.color3 {
  background: var(--color-3);
}
.content section.intro .left .leftBar div.color4 {
  background: var(--color-4);
}

.content section.intro .left .social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.content section.intro .left .social a {
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
}
.content section.intro .left .social a:hover {
  opacity: 0.75;
}

.content section.intro .left .social a:first-of-type {
  margin-right: 1rem;
}

.content section.intro .right {
  background: var(--color-1--10);
}
.content section.intro .right .rightBar {
  background: var(--color-1);
}
.content section.intro .right p {
  padding-left: 0.5rem;
}

.content section.experience .right {
  background: var(--color-2--10);
}
.content section.experience .right .rightBar {
  background: var(--color-2);
}
.content section.education .right {
  background: var(--color-3--10);
}
.content section.education .right .rightBar {
  background: var(--color-3);
}
.content section.contact .right {
  background: var(--color-4--10);
}
.content section.contact .right .rightBar {
  background: var(--color-4);
}

.content section.experience .left h2 {
  color: var(--color-2);
}

.content section.education .left h2 {
  color: var(--color-3);
}

.content section.education .right ul {
  padding-left: 0.5rem;
}

.content section.education .right ul li {
  list-style: none;
}

.content section.contact .left h2 {
  color: var(--color-4);
}

.content section.contact .right form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 0.5rem;
}
.content section.contact .right input[type="text"],
.content section.contact .right input[type="email"],
.content section.contact .right textarea {
  box-sizing: border-box;
  background: rgba( 255, 255, 255, 0.9 );
  border-radius: 0.375rem;
  padding: 1rem 1.5rem;
  width: 100%;
  margin: 0;
  margin-bottom: 1rem;
  border: 1px solid rgba( 0, 0, 0, 0.2 );
  box-shadow: 1px 1px 6px 0 rgba( 0, 0, 0, 0.1 );
  font-size: 1.125rem;
  resize: vertical;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content section.contact .right input[type="text"]:focus,
.content section.contact .right input[type="email"]:focus,
.content section.contact .right textarea:focus {
  border: 1px solid rgba( 0, 0, 0, 0.3 );
  background: rgba( 255, 255, 255, 0.75 );
  outline: none;
}

.content section.contact .right .formActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content section.contact .right .social {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content section.contact .right .social a {
  transition: all 0.3s ease-in-out;
}
.content section.contact .right .social a:hover {
  opacity: 0.75;
}

.content section.contact .right .social a:first-of-type {
  margin-right: 1rem;
}

.content section.contact .right .formActions button {
  background: var(--color-4);
  color: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 6px 0 rgba( 0, 0, 0, 0.1 );
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  font-size: 1.125rem;
  width: 8rem;
}
.content section.contact .right .formActions button:hover {
  box-shadow: 2px 2px 8px 0 rgba( 0, 0, 0, 0.2 );
  opacity: 0.8;
}
.content section.contact .right .formActions button:focus {
  outline: none;
}